import { connect } from 'react-redux';
import { updateUserName ,disAppearMsg} from '../../../Redux/Modules/User';
import Profile from '../../../Components/PageContainer/Profile/Profile';


const mapStateToProps = ({ UserReducer}) => {
    return {
        message : UserReducer.message,
        status : UserReducer.status,
        username : UserReducer.UserName,
        nickname : UserReducer.NickName
    };
};


const mapDispatchToProps = dispatch => ({
    updateUserName: (uname) => dispatch(updateUserName(uname)),
    disAppearMsg: () => dispatch(disAppearMsg())
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);