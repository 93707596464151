import React, { Component } from 'react';
import TaskHint from './TaskHint';

class Hint extends Component {
    render() {      
        return (   
            <div className="hint-contentBox  hint-openBox">
                <TaskHint ShowHint={this.props.ShowHint}/>
            </div>
      );
    }
}

export default Hint;