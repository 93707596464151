import React, { Component } from 'react';
import {connect} from "react-redux";
import TaskComplexity from './TaskComplexity';
import FinalHint from './FinalHint';
import CustomScrollbars from './../../Global/CustomScrollbars/CustomScrollbars';
import CustomImg from '../../Global/CustomImg/CustomImg';

class TaskHint extends Component {

    constructor() {
        super();
        this.state={
            taskId:-1,
            showTaskHint : true,
            showTaskHintComplex : false,
            showFinalHint : false,
            clueData:{}
        }
    }

    selectTaskComplexity = (taskId) => {       
        this.setState({taskId, 
            showTaskHint: !this.state.showTaskHint,
            showTaskHintComplex : !this.state.showTaskHintComplex,
            showFinalHint : false
        });        
    }

    showTaskComplexity = () => {
        this.setState({ showTaskHint: !this.state.showTaskHint, showTaskHintComplex : !this.state.showTaskHintComplex });     
    }

    showFinalHint = (clue) => {
        this.setState({ showTaskHint: false, showTaskHintComplex : false , showFinalHint :true,clueData:clue });     
    }
   
    render() {
        return (             
            <React.Fragment>
                <CustomScrollbars>
                { this.state.showTaskHint && <div>
                        <button className="close" onClick={() => this.props.ShowHint()} >
                            <CustomImg src="gradiant-close.svg" className="img-close" alt="img-close" />
                        </button>
                    <div id="what-task-box" className={ "step-one text-center d-block" }>
                        <div className="contentHint">
                                <h1>
                                    <CustomImg src="gradiant-explanation-icon.svg" className="" alt="gradiant-explanation-icon" />
                                    what task do you need help with?</h1>
                            <div className="w100 mt-4">
                                { 
                                    this.props.TaskReducer.tasks.map((data,index)=> {
                                    return data.isCompleted ?(<button key={data.id} className="red-btn">
                                    <div className="redcolor easy-btn"><span>TASK {index + 1}</span></div>
                                </button>) : (<button key={data.id} className="red-btn" onClick = {() => this.selectTaskComplexity(index)}>
                                            <div className="redcolor"><span>TASK {index + 1}</span></div>
                                        </button>)
                                    })
                                }                
                            </div>
                        
                        </div>

                    </div>
                </div>
                }
                { (this.state.taskId >= 0 && this.state.showTaskHintComplex) && 
                        <TaskComplexity showFinalHint={(clue)=>this.showFinalHint(clue)} showTaskComplexity={this.showTaskComplexity} taskId = {this.state.taskId} />}

                { (this.state.taskId >= 0 && this.state.showFinalHint) && 
                        <FinalHint showFinalHint={(clue)=>this.showFinalHint(clue)} taskId = {this.state.taskId} clueData={this.state.clueData}/>}
                </CustomScrollbars>
            </React.Fragment>
      );
    }
}

const mapStateToProps =({TaskReducer})=>{
    return {TaskReducer}
}

export default connect(mapStateToProps,null)(TaskHint);