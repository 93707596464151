import React, { Component } from 'react';

class Home extends Component {
    render() {

        return (
             <div id="container">
          <div className="page_content">
          <div className="videos-holder">

           </div></div>
           
           </div>
       );
    }
}

export default Home;