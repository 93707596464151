import { connect } from 'react-redux';


import Header from '../../../Components/Layout/Header';

import { getAllTasks,setCurrentTask , markAsCompleted , getEnrolled } from '../../../Redux/Modules/Tasks';

const mapStateToProps = ({ TaskReducer , UserReducer }) => { 
    // console.log("Header TaskReducer ",TaskReducer);
    return {
            tasks:TaskReducer.tasks,
            currentTask:TaskReducer.CurrentTask,
            isExperienceCompleted : TaskReducer.isExperienceCompleted,
            isGameFinish : TaskReducer.isGameFinish,
            isExperienceGameFinished : TaskReducer.isExperienceGameFinished,
            userName : UserReducer.UserName,
            nickname : UserReducer.NickName
        };
};

const mapDispatchToProps = dispatch => ({
    getAllTasks: () => dispatch(getAllTasks()),
    getEnrolled: () => dispatch(getEnrolled()),
    setCurrentTask: (id) => dispatch(setCurrentTask(id)),
    markAsCompleted: (tid) => dispatch(markAsCompleted(tid))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);