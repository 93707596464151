import { connect } from 'react-redux';
import { getAllFiles } from './../../../Redux/Modules/Files';
import Files from '../../../Components/PageContainer/Files/Files';

const mapStateToProps = ({ FilesReducer , TaskReducer }) => {
    return {
        files : FilesReducer.data,
        currentTask : TaskReducer.CurrentTask,
        isExperienceGameFinished : TaskReducer.isExperienceGameFinished,
        isGameFinish : TaskReducer.isGameFinish
    };
};

const mapDispatchToProps = dispatch => ({
    getAllFiles: (tid) => dispatch(getAllFiles(tid))
});

export default connect(mapStateToProps, mapDispatchToProps)(Files);