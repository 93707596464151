import { connect } from 'react-redux';

import Video from '../../../Components/PageContainer/Video/Video';

import { getAllVideos } from '../../../Redux/Modules/Videos';
import { markCurrentTaskVideoPlayed , markExperienceCompleted , markExperienceAsRead } from '../../../Redux/Modules/Tasks';

const mapStateToProps = ({ VideoReducer, TaskReducer }) => {    
    return {
                videos : VideoReducer.data, 
                task_name : VideoReducer.task_name,
                currentTask : TaskReducer.CurrentTask,
                isGameFinish : TaskReducer.isGameFinish,
                video_start : TaskReducer.video_start,
                video_end : TaskReducer.video_end
            };
};

const mapDispatchToProps = dispatch => ({
    getAllVideos: (id) => dispatch(getAllVideos(id)),
    markCurrentTaskVideoPlayed : (id) => dispatch(markCurrentTaskVideoPlayed(id)),
    markExperienceCompleted : () => dispatch(markExperienceCompleted()),
    markExperienceAsRead : () => dispatch(markExperienceAsRead())
});

export default connect(mapStateToProps, mapDispatchToProps)(Video);