import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import RootReducer from "./RootReducer";

// Allow for Redux devtools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Single application store
const Store = createStore(
  RootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default Store;
