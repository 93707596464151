let isAiccEnable =  true;//(process.env.REACT_APP_ISAICC_ENABLE == 0)?false:true;
const aicc_api = new function () {
    /*
        * ---------------------------------------------------------------------
        * Debug functions
        * ---------------------------------------------------------------------
        */

    var strDebug = "";
    var lngDebugLine = 0;
    var winDebug;
    var isDebug = false;
    //var proxyFilePath = "https://tegcoursesapi.devdigital.com/proxy.php";
    //var proxyFilePath = "https://tegcoursesapi.devdigital.com:8083/api/v1/lms/send";
    var proxyFilePath = process.env.REACT_APP_AICC_PROXY;
   
    function WriteToDebug(strInfo){
        
        if(isDebug == true) { 
            var dtm = new Date();
            var strLine;
                
            lngDebugLine++;
                
            strLine = lngDebugLine + ":" + dtm.toString() + " - " + strInfo + "<br>\n";
                
            strDebug += strLine;
                
            if (winDebug && !winDebug.closed){
                //winDebug.document.write(strLine);
             //   console.log(strLine);
            } else {
              //  console.log(strLine);
            }

            return;
        }
    }

    function ShowDebugWindow(){

        if (winDebug && !winDebug.closed){
            winDebug.close();
        }
        
        winDebug = window.open("", "Debug", "width=600,height=300,resizable,scrollbars");
        
        winDebug.document.write(strDebug);
        
        return;
    }

    /*
        * ---------------------------------------------------------------------
        * Create XMLHTTP object
        * ---------------------------------------------------------------------
        */

    var objXMLHTTP = null;
        WriteToDebug("Entered browser conditional");
        try{
            WriteToDebug("Creating object");
            objXMLHTTP = new XMLHttpRequest();
        }
        catch (e){
            //XMLHTTP object could not be created
            WriteToDebug("Could not create object, exception=" + e);
            objXMLHTTP = null;
        }
    /*
        * ---------------------------------------------------------------------
        * Real page code beins here
        * ---------------------------------------------------------------------
        */

    //variables to check for the IFrame's load
    var intReCheckLoadedInterval = 250;			//check every quarter of a second (want this to be small so first GetParam doesn't produce a noticable delay)
    var intReCheckAttemptsBeforeTimeout = 240;  //240 * 250 ms = 60 second time-out - adjust as needed
    var intReCheckAttempts = 0;

    var IFrameLoaded_TimeOutID = "";
    var blnIFrameLoaded = false;

    //AICC Data - content will set these variables (through public methods) for submission to the LMS
    var strLessonLocation = "";
    var strLessonStatus = "i";
    var strAiccScore = "";
    var strTime = "00:00:00";
    var strVersion = "";
    var strCoreLesson = "";		// added 2016-06-28 CT
    var strStudentName = "";
    var strStudentId = "";

    //Communication Capabilities of the current browser - we check the actual capabilities 
    //rather than specific browsers to ensure forward compatibility and compatibility with untested browsers
    var blnCanUseXMLHTTP;		//set in the onload event
    var blnCanUseIFrame;

    //constants
    var REQUEST_TYPE_GET = "GETPARAM";
    var REQUEST_TYPE_PUT = "PUTPARAM";
    var REQUEST_TYPE_EXIT = "EXITAU";

    var LESSON_STATUS_PASSED = "p";
    var LESSON_STATUS_FAILED = "f";
    var LESSON_STATUS_COMPLETED = "c";
    var LESSON_STATUS_BROWSED = "b";
    var LESSON_STATUS_INCOMPLETE = "i";
    var LESSON_STATUS_NOT_ATTEMPTED = "n";
    
    // debug control
    var showDebug = true;


    this.setPassed = function () { SetStatus(LESSON_STATUS_PASSED); MakeAiccPutParamRequest();}
    this.setFailed = function () { SetStatus(LESSON_STATUS_FAILED); MakeAiccPutParamRequest(); }
    this.setCompleted = function () { SetStatus(LESSON_STATUS_COMPLETED); MakeAiccPutParamRequest();}
    this.setBrowsed = function () { SetStatus(LESSON_STATUS_BROWSED); MakeAiccPutParamRequest(); }
    this.setIncomplete = function () { SetStatus(LESSON_STATUS_INCOMPLETE); MakeAiccPutParamRequest()}
    this.setNotAttempted = function () { SetStatus(LESSON_STATUS_NOT_ATTEMPTED); }
    this.setLessonLocation = function (value) { SetBookmark(value); MakeAiccPutParamRequest(); }
    this.getLessonLocation = function () { return GetBookmark();  }
    this.setScore = function (value) { /*value = parseInt(GetScore())+parseInt(value);*/  SetScore(value);MakeAiccPutParamRequest(); }
      this.setTime = function(time) { SetTime(time); MakeAiccPutParamRequest();}
    this.getStudentName = function () { return strStudentName; }
    this.getScore = function () { return GetScore();  }   
    this.getStudentId = function() { return strStudentId; }
    this.getAiccUrlLms = function() { return (isAiccEnable)?GetAICCURL:''; } 
   // this.getLessonStatus = function() { return AICC_Lesson_Status;}
   // this.returnQueryString = function() { return getQueryString(); }
    //---------------------------------------------------------------------
    //Public Functions
    //---------------------------------------------------------------------
    
    this.init = function() {
        if(isAiccEnable) { 
            DetectPreferredCommMethod();
            MakeGetParamRequest();
        }
    }
    function MakeGetParamRequest(){       
        
        var strAICCSID;
        var strAICCURL;
        
        WriteToDebug ("In MakeGetParamRequest");
        
        strAICCSID =   GetAICCSID();
        strAICCURL = GetAICCURL();
        
        WriteToDebug ("Submitting Form");
        
        SubmitForm(strAICCURL, strAICCSID, REQUEST_TYPE_GET, "");
    }
    function MakeExitAURequest(){

        var strAICCSID;
        var strAICCURL;
        
        WriteToDebug ("In MakeExitAURequest");
        
        strAICCSID = GetAICCSID();
        strAICCURL = GetAICCURL();
            
        WriteToDebug ("Submitting Form");
        
        SubmitForm(strAICCURL, strAICCSID, REQUEST_TYPE_EXIT, "");
        
    }

    function SetCoreLesson(str){
        WriteToDebug("In SetCoreLesson, str=" + str);
        strCoreLesson = str;
    }

    function GetCoreLesson(){
        WriteToDebug("In GetBookmark, returning=" + strCoreLesson);
        return strCoreLesson;
    }

    function SetBookmark(strBookmark){
        WriteToDebug("In SetBookmark, strBookmark=" + strBookmark);
        strLessonLocation = strBookmark;
    }

    function GetBookmark(){
        WriteToDebug("In GetBookmark, returning=" + strLessonLocation);
        return strLessonLocation;
    }

    function SetStatus(strStatus){
        WriteToDebug("In SetStatus, strStatus=" + strStatus);
        if (strStatus !== "") {
            strLessonStatus = strStatus;
        }
    }

    function GetStatus(){
        WriteToDebug("In GetStatus, returning=" + strLessonStatus);
        return strLessonStatus;
    }

    function SetTime(intTotalMilliSeconds){
        WriteToDebug("In SetTime, intTotalMilliSeconds=" + intTotalMilliSeconds);
       // strTime = ConvertMSToCMI(intTotalMilliSeconds);	
        strTime = intTotalMilliSeconds;
        return strTime;
    }

    function SetScore(strSetScore){
    // decimal score was introduced in AICC 3.05 - remove decimal from older versions
        if (strVersion && (parseFloat(strVersion) < 3.1)) {
            strSetScore = parseInt(strSetScore);
            strSetScore = strSetScore.toString();
        }
        WriteToDebug("In SetScore, strAiccScore=" + strSetScore);
        strAiccScore = strSetScore;
        WriteToDebug("In SetScore, strAiccScore====" + strAiccScore);
        //MakeAiccPutParamRequest(); 
        WriteToDebug("In SetScore after MakeAICCPutRequest, strAiccScore====" + strAiccScore);
    }
    
    function MakeAiccPutParamRequest(){

        if(!isAiccEnable) return;
        var strAICCSID;
        var strAICCURL;
        var strAICCData;
        
        WriteToDebug ("In MakeAiccPutParamRequest"+GetScore());
        
        strAICCSID = GetAICCSID();
        strAICCURL = GetAICCURL();
        WriteToDebug ("Before Submitting Form FormAICCPostData "+strAiccScore);
        strAICCData = FormAICCPostData();
        
        WriteToDebug ("Submitting Form");
        
        SubmitForm(strAICCURL, strAICCSID, REQUEST_TYPE_PUT, strAICCData);

    }
    
    function GetScore() {
        WriteToDebug ("In AICC getScore = "+strAiccScore);
        return (strAiccScore=="")?0:strAiccScore;
    }



    //---------------------------------------------------------------------
    //Private Functions
    //---------------------------------------------------------------------


    //---------------------------------------------------------------------
    //IFrame Functions
    //---------------------------------------------------------------------


    function CheckIFrameLoaded(strRequestType){
        
        WriteToDebug("In CheckIFrameLoaded strRequestType=" + strRequestType);
        
        if (blnIFrameLoaded){
            
            WriteToDebug("Frame Loaded");
            
            ProcessLMSResult(strRequestType, GetIFrameContents());
            
        }
        else{
            //re-call, check for time out
            
            WriteToDebug("Frame Not Loaded");
            
            intReCheckAttempts ++;
            
            if (intReCheckAttempts > intReCheckAttemptsBeforeTimeout){
                
                WriteToDebug("Frame Timeout Error");
                
                DisplayError("The LMS timed out while responding to an AICC request.");
                
            }
            else{
                WriteToDebug("Resetting CheckIFrameLoaded");
                IFrameLoaded_TimeOutID = window.setTimeout("CheckIFrameLoaded('" + strRequestType + "')", intReCheckLoadedInterval);
            }	
            
        }
    }


    function IFrameLoaded(){
        
        WriteToDebug(" IFrameLoaded ");
        
        blnIFrameLoaded = true;

    }


    function GetIFrameContents(){
        
        var strContents;
        
        WriteToDebug("In GetIFrameContents");
        
        strContents = window.AICCFrame.document.body.innerHTML;
        
        WriteToDebug("strContents=" + strContents);
        
        return strContents;
    }


    function SubmitFormUsingIFrame(strAICCURL, strAICCSID, strRequestType, strAICCData){
        
        WriteToDebug ("In SubmitFormUsingIFrame, setting fields");
        document.frmAICC.action = strAICCURL;

        document.frmAICC.session_id.value = strAICCSID;
        document.frmAICC.command.value = strRequestType;
        document.frmAICC.aicc_data.value = strAICCData;
        
        WriteToDebug ("Submitting Form");
        
        document.frmAICC.submit();

        blnIFrameLoaded = false;
        intReCheckAttempts = 0;
        
        WriteToDebug ("Clearing Timeout");
        
        if (IFrameLoaded_TimeOutID != ""){
            window.clearTimeout(IFrameLoaded_TimeOutID);
            IFrameLoaded_TimeOutID = "";
        }
        
        CheckIFrameLoaded(strRequestType);
    }


    //---------------------------------------------------------------------
    //XML HTTP Object Functions
    //---------------------------------------------------------------------
    function SubmitFormUsingXMLHTTP(strAICCURL, strAICCSID, strRequestType, strAICCData, strPHPName, strPHPSID){
        
        var strReturn;
        var strPostData;
        
        WriteToDebug ("In SubmitFormUsingXMLHTTP, opening connetion AICCURL=" + strAICCURL);

    //	objXMLHTTP.open ("POST", strAICCURL, false);
        objXMLHTTP.open ("POST", proxyFilePath+"?url=" + URLEncode(strAICCURL), false);
        
        WriteToDebug ("Setting Request Header");

        objXMLHTTP.setRequestHeader ("Content-Type", "application/x-www-form-urlencoded");
        
        WriteToDebug ("Creating Post Data");
            
        strPostData = "session_id=" + encodeURIComponent(strAICCSID) + 
                        "&version=3.5" + 
                        "&command=" + encodeURIComponent(strRequestType) +
                        "&aicc_data=" + encodeURIComponent(strAICCData);
        if (strPHPName != null) {
            strPostData = strPostData + "&" + strPHPName + "=" + strPHPSID;
        }
    // 2010-06-29 removde passing session id
    // 				  "&aicc_data=" + URLEncode(strAICCData) +
    // 				  "&<?php echo session_name() . '=' . session_id(); ?>";
        
        WriteToDebug ("Sending Post Data-" + strPostData);
        
        objXMLHTTP.send (strPostData);
        
        WriteToDebug ("Looking up Response Text");
                            
        strReturn = objXMLHTTP.responseText;

        WriteToDebug ("LMS Response=" + strReturn);
        
        ProcessLMSResult(strRequestType, strReturn);
    }


    function URLEncode(str){
        
        str = new String(str);
        
        str = escape(str);
        str = str.replace(/%20/g, "+");
        
        return str;
    }


    //---------------------------------------------------------------------
    //Blind Form Submit Functions
    //---------------------------------------------------------------------

    function SubmitFormNormally(strAICCURL, strAICCSID, strRequestType, strAICCData){

        WriteToDebug ("In SubmitFormNormally, setting fields");
        
        document.frmAICC.target = "f_medcom_aicc_results";		//keep this name fairly unique to avoid a potential naming conflict with LMS frames
        
        document.frmAICC.action = strAICCURL;
        document.frmAICC.session_id.value = strAICCSID;
        document.frmAICC.command.value = strRequestType;
        document.frmAICC.aicc_data.value = strAICCData;
        
        WriteToDebug ("Submitting Form");
        
        document.frmAICC.submit();
        
        ProcessLMSResult(strRequestType, "");
    }



    //---------------------------------------------------------------------
    //Form Submission Functions
    //---------------------------------------------------------------------


    function DetectPreferredCommMethod(){
        
        //if we have an XMLHTTP object, use that
        //else, if we can see the IFrame, use that
        //else - use a blind (write only) form submit
        
        WriteToDebug("In DetectPreferredCommMethod, checking XMLHTTP");
        
        if (objXMLHTTP != null){
            blnCanUseXMLHTTP = true;
        }
        else{
            blnCanUseXMLHTTP = false;
        }	
    //	blnCanUseXMLHTTP = false;	// to force frames
        
        WriteToDebug("Checking IFrame, blnCanUseXMLHTTP=" + blnCanUseXMLHTTP);

        //note use of short circuit AND to prevent error if browser doesn't recognize part of the IFrame
        //in Opera 7, there needs to be something in the body of the IFrame for the last condition to evaluate to true
        //in opera 7.1, all tests will pass, but the onload event doesn not fire due to a bug, add a check for blnIFrameLoaded
        //to ensure that the onload event fired
        if (window.AICCFrame && 
            window.AICCFrame.document && 
            window.AICCFrame.document.body && 
            window.AICCFrame.document.body.innerHTML &&
            blnIFrameLoaded){	
            blnCanUseIFrame = true;
        }
        else{
            blnCanUseIFrame = false;
        }	
        
        WriteToDebug("blnCanUseIFrame=" + blnCanUseIFrame);
    }


    function SubmitForm(strAICCURL, strAICCSID, strRequestType, strAICCData){
     
        if(isAiccEnable) { 
            WriteToDebug ("In SubmitForm, setting fields");
            WriteToDebug ("strAICCURL = " + strAICCURL);
            WriteToDebug ("strAICCSID = " + strAICCSID);
            WriteToDebug ("strCommand = " + strRequestType);
            WriteToDebug ("strAICCData = " + strAICCData);
            
            
            if (blnCanUseXMLHTTP){
                SubmitFormUsingXMLHTTP(strAICCURL, strAICCSID, strRequestType, strAICCData);
            }
            else if(blnCanUseIFrame){
                SubmitFormUsingIFrame(strAICCURL, strAICCSID, strRequestType, strAICCData);
            }
            else{
                SubmitFormNormally(strAICCURL, strAICCSID, strRequestType, strAICCData);
            }
        }
        
    }



    //---------------------------------------------------------------------
    //AICC Functions
    //---------------------------------------------------------------------

    function ProcessLMSResult(strRequestType, strLMSResult){
        
        WriteToDebug("In ProcessLMSResult, strRequestType=" + strRequestType + " strLMSResult=" + strLMSResult)
        
        var blnMadeBlindSubmit;
        
        var strAICCErrorLine = "";
        var strAICCErrorTextLine = "";
        var strAICCLessonLocationLine = "";
        var strAICCStudentNameLine = "";
        var strAICCStudentIdLine = "";
        var strAICCScoreLine = "";				// added 20060112 CT
        var strAICCVersionLine = "";			// added 20060131 CT
        var strAICCLessonStatusLine = "";		// added 20060410 CT
        
        var aryAICCResponseLines;
        var strLine;
        
        var intPos;
        
        //if we made a blind submit with a standard form, we can't read the result, so just proceed
        blnMadeBlindSubmit = (!blnCanUseXMLHTTP && !blnCanUseIFrame);
        
        WriteToDebug("blnMadeBlindSubmit=" + blnMadeBlindSubmit);
        
        //we only care about two things: 1) Error information (two lines, number and text), 
        //2) Lesson location information, so we just extract those lines
        
        strLMSResult = new String(strLMSResult);
        aryAICCResponseLines = strLMSResult.split("\n");	//only use \n instead of \r\n b/c some LMS's will only use one character
        
        for (var i=0; i < aryAICCResponseLines.length; i++){
            
            WriteToDebug("Processing Line #" + i + ": " + aryAICCResponseLines[i]);
            
            strLine = aryAICCResponseLines[i];
            
            if (strLine.toLowerCase().indexOf("error") > -1){
                if (strLine.toLowerCase().indexOf("error_text") > -1){
                    WriteToDebug("Found Error Text Line");
                    strAICCErrorTextLine = strLine;
                }
                else{
                    WriteToDebug("Found Error Number Line");
                    strAICCErrorLine = strLine;
                }
            }
            else if (strLine.toLowerCase().indexOf("lesson_location") > -1){
                WriteToDebug("Found Lesson Location Line");
                strAICCLessonLocationLine = strLine.replace("\r", "");	//strip out the line feed at the end if it exists
            }else if (strLine.toLowerCase().indexOf("student_name") > -1){
                WriteToDebug("Found Setudent Name Line");
                strAICCStudentNameLine = strLine.replace("\r", "");	//strip out the line feed at the end if it exists
                strStudentName = strAICCStudentNameLine;
                
                strStudentName = strStudentName.toLowerCase().replace("student_name=","");
                WriteToDebug("Found Setudent Name Line "+strStudentName);
                
            }  else if (strLine.toLowerCase().indexOf("student_id") > -1){
                WriteToDebug("Found Setudent Id Line");
                strAICCStudentIdLine = strLine.replace("\r", "");	//strip out the line feed at the end if it exists
                strStudentId = strAICCStudentIdLine;
                
                strStudentId = strStudentId.toLowerCase().replace("student_id=","");
                WriteToDebug("Found Setudent Id Line "+strStudentId);
                
            }   /*student_id */
            else if (strLine.toLowerCase().indexOf("score=") == 0){
                WriteToDebug("Found Score Line");
                strAICCScoreLine = strLine.replace("\r", "");	//strip out the line feed at the end if it exists
            }
            else if (strLine.toLowerCase().indexOf("version=") == 0){
                WriteToDebug("Found Version Line");
                strAICCVersionLine = strLine.replace("\r", "");	//strip out the line feed at the end if it exists
            }
            else if (strLine.toLowerCase().indexOf("lesson_status=") == 0){
                WriteToDebug("Found Lession Status Line");
                strLine = strLine.replace(/,.*/, "");	//strip out resume
                strLine = strLine.replace("\r", "");	//strip out the line feed at the end if it exists
                strAICCLessonStatusLine = strLine;
            }
            else if (strLine.toLowerCase().indexOf("[core_lesson]") == 0){		// added 2016-06-28 CT
                WriteToDebug("Found [Core_Lession] Line");
                ++i;
                if ((i < aryAICCResponseLines.length) && (aryAICCResponseLines[i].substr(0, 1) != "[")) {	// if next line not new block then get data
                    strLine = aryAICCResponseLines[i];
    //console.log("Found [Core_Lession] Line="+strLine);
                    strCoreLesson = strLine.replace("\r", "");	//strip out the line feed at the end if it exists
                } else {
                    --i;	// no core lesson data
                }
            }

        }
        
        
        
        
        //check for errors and alert if found, check for AICC error, as well as HTTP error like 404
        if (!blnMadeBlindSubmit){
        
            if (strAICCErrorLine == ""){
                DisplayError("LMS did not return a valid status code.");
                return;
            }
            
            if (strAICCErrorLine.indexOf("0") == -1){
    //			DisplayError("LMS returned an error - " + strAICCErrorLine);
                if (strAICCErrorLine.indexOf("3") > 0){
                    DisplayError("Please close the course, verify you are logged-in, then relaunch the course.\n\nLMS returned:\n" + strAICCErrorLine + strAICCErrorTextLine);
                } else {
                    DisplayError("LMS returned:\n" + strAICCErrorLine + strAICCErrorTextLine);
                }
                return;
            }
        }
        
        
        if (strRequestType == REQUEST_TYPE_GET){
            
            WriteToDebug("In request type = get");
            
            //keep default values if we made a blind submit
            if (!blnMadeBlindSubmit){
                
                intPos = strAICCLessonLocationLine.indexOf("=");
                
                WriteToDebug("Lesson location equal position=" + intPos);
                
                if (intPos > -1){
                    if (intPos < strAICCLessonLocationLine.length){
                        strLessonLocation = strAICCLessonLocationLine.substr(intPos + 1);
                    }
                    else{
                        strLessonLocation = "";
                    }
                    WriteToDebug("strLessonLocation=" + strLessonLocation);
                }
                else{
                    WriteToDebug("ERROR - NON FATAL - LMS did not return a proper lesson_location line");
                }
                
                intPos = strAICCScoreLine.indexOf("=") + 1;
                WriteToDebug("Score equal position=" + intPos);
                if (intPos > -1){
    //				intLength = strAICCScoreLine.length;
    //				WriteToDebug("ScoreLine length=" + intLength);
                    if (intPos < strAICCScoreLine.length){
                        strAiccScore = strAICCScoreLine.substr(intPos);
                        WriteToDebug("Score has value"+strAiccScore);
    //				}else{
    //					WriteToDebug("Score has NO value");
                    }
                    WriteToDebug("strAiccScore=" + strAiccScore+"   "+strAICCScoreLine+"   "+strAICCScoreLine.length);
                }
                
                intPos = strAICCVersionLine.indexOf("=") + 1;
                WriteToDebug("Version equal position=" + intPos);
                if (intPos > -1){
    //				intLength = strAICCVersionLine.length;
    //				WriteToDebug("VersionLine length=" + intLength);
                    if (intPos < strAICCVersionLine.length){
                        strVersion = strAICCVersionLine.substr(intPos);
    //					WriteToDebug("Version has value");
    //				}else{
    //					WriteToDebug("Version has NO value");
                    }
                    WriteToDebug("strVersion=" + strVersion);
                }

                intPos = strAICCLessonStatusLine.indexOf("=") + 1;
                WriteToDebug("Lesson Status equal position=" + intPos);
                if (intPos > -1){
                    if (intPos < strAICCLessonStatusLine.length){
    // echo back any status sent from the LMS except n/Not Attempted
                        if (strAICCLessonStatusLine.substr(intPos, 1).toLowerCase() != "n") {
                            strLessonStatus = strAICCLessonStatusLine.substr(intPos);
                        }
                    }
                    WriteToDebug("strLessonStatus=" + strLessonStatus);
                }
            }
            
            WriteToDebug("Calling Start Content");
            
            //StartContent();
        }
        
    }

    function FormAICCPostData(){

        /*
        aicc_data has the following format:
        [Core] \r\n
        Lesson_Location={bookmark} \r\n
        Lesson_Staus={status} \r\n
        Score={score} \r\n
        Time={time} \r\n
        */
        
        WriteToDebug ("In FormAICCPostData");
        
        var strAICCData = "";

        strAICCData = "[Core]\r\n";
        strAICCData += "Lesson_Location=" + strLessonLocation + "\r\n";
        strAICCData += "Lesson_Status=" + strLessonStatus + "\r\n";
        
        var status = strLessonStatus.substr(0, 1);
        status = status.toLowerCase();
    //	if ((status != "p") && (status != "f")) {
    //			strAiccScore = "";
    //	}
    
    WriteToDebug ("In FormAICCPostData  setscore "+strAiccScore);
        if ((status == "f") || ((status == "p") && (strAiccScore > 0))) {
            strAICCData += "Score=" + strAiccScore + "\r\n";
        } else {
            strAICCData += "Score=" + strAiccScore + "\r\n";
        }
       // var aiccDate = new Date();
       // var dateMilliseconds = aiccDate.getMilliseconds();
       // strTime = SetTime(dateMilliseconds);
        //console.log("StrTimme "+ strTime);

        strAICCData += "Time=" + strTime + "\r\n";
        if (strCoreLesson) {
            strAICCData += "[Core_Lesson]\r\n";
            strAICCData += strCoreLesson + "\r\n";
        }
        
        WriteToDebug ("Returning- " + strAICCData);
        
        return strAICCData;
    }

    function GetAICCSID(){
        
        var strSID = "";
        var localStrSid="";		
        
        strSID = GetQueryStringValue("AICC_SID", document.location.search);
        if(strSID == "") {
            strSID = GetQueryStringValue("aicc_sid", document.location.search);
        }
        if(strSID == "") {
            localStrSid = sessionStorage.getItem( "AICC_SID");
            //console.log(" local get ICC ",localStrSid);
            strSID = localStrSid;
        } else {
            sessionStorage.setItem( "AICC_SID", strSID);
            WriteToDebug("SetAICCSID in local storage ",strSID);
        }
            
        /*localStrSid = sessionStorage.getItem( "AICC_SID");
        
        if(localStrSid == null ) {
            WriteToDebug("GetAICCSID NOT found in local storage");
            strSID = GetQueryStringValue("AICC_SID", document.location.search);
            if(strSID == "") {
                strSID = GetQueryStringValue("aicc_sid", document.location.search);
            }
            
            WriteToDebug("SetAICCSID in local storage");
        } else {
            strSID = localStrSid;
        }
        console.log("localStrSid == ",strSID);*/
        WriteToDebug("GetAICCSID returning: " + strSID);
        
        return strSID;
        
    }

    function GetAICCURL(){
        
       // console.log("document.location = ", document.location);
        var strURL = "";
        var localStrURL="";
        WriteToDebug("In GetAICCURL");
        strURL = GetQueryStringValue("AICC_URL", document.location.search);
        if(strURL == "") {
            strURL = GetQueryStringValue("aicc_url", document.location.search);
        }
        if(strURL == "") {
            localStrURL = sessionStorage.getItem( "AICC_URL");
           // console.log(" local get ICC ",localStrURL);
            strURL = localStrURL;
        } else {
            sessionStorage.setItem( "AICC_URL", strURL);
            WriteToDebug("SetAICCURL in local storage ",strURL);
        }
        
        
        
        /*localStrURL = sessionStorage.getItem( "AICC_URL");
        
        if(localStrURL == null) {
            WriteToDebug("GetAICCURL NOT found in local storage");
            strURL = GetQueryStringValue("AICC_URL", document.location.search);
            if(strURL == "") {
                strURL = GetQueryStringValue("aicc_url", document.location.search);
            }
            sessionStorage.setItem( "AICC_URL", strURL);
            WriteToDebug("SetAICCURL in local storage");
        }else {
            strURL = localStrURL;
        }	*/
        
        //strURL = GetQueryStringValue("AICC_URL", document.location.search)
       // console.log("localStrURL == ",strURL);
        strURL = strURL.replace(/^(http|https)[:]/, "");
        
        WriteToDebug("GetAICCURL returning: " + strURL);
        
        return strURL;

    }


    function ConvertMSToCMI(intTotalMilliSeconds){

        var intHours;
        var intMinutes;
        var intSeconds;
        var intMilliSeconds;
        var strCMITime;
        
        //convert intMilliSeconds into CMI timespan
        
        WriteToDebug("In ConvertMSToCMI, intTotalMilliSeconds=" + intTotalMilliSeconds);
        
        intHours = Math.floor(intTotalMilliSeconds / 3600000);
        intTotalMilliSeconds = intTotalMilliSeconds % 3600000;
            
        intMinutes = Math.floor(intTotalMilliSeconds / 60000);
        intTotalMilliSeconds = intTotalMilliSeconds % 60000;
            
        intSeconds = Math.floor(intTotalMilliSeconds / 1000);
        intTotalMilliSeconds = intTotalMilliSeconds % 1000;
            
        intMilliSeconds = intTotalMilliSeconds;
        
        WriteToDebug("intHours=" + intHours + "intMinutes=" + intMinutes + "intSeconds=" + intSeconds + "intMilliSeconds=" + intMilliSeconds);
        
        strCMITime = ZeroPad(intHours, 4) + ":" + ZeroPad(intMinutes, 2) + ":" + ZeroPad(intSeconds, 2);
        
        if (intHours > 9999){		//check for max date
            strCMITime = "9999:99:99";
        }	
        
        WriteToDebug("Returning-" + strCMITime);
        
        return strCMITime;
    }

    //---------------------------------------------------------------------
    //Utility Functions
    //---------------------------------------------------------------------


    function DisplayError(strMessage){
        
        var blnShowDebug;
        
        WriteToDebug("In DisplayError, strMessage=" + strMessage + ", showDebug=" + showDebug);

        if (showDebug) {
            blnShowDebug = alert("An error has occured:\n\n" + strMessage + "\n\nPress 'OK' to view debug information to send to technical support.");

            if (blnShowDebug){
                ShowDebugWindow();
            }
        }
        
    }

    function GetQueryStringValue(strElement, strQueryString){
            
        var aryPairs;
        var i;
        var intEqualPos;
        var strArg = "";
        var strValue = "";
        
        WriteToDebug("In GetQueryStringValue strElement=" + strElement + ", strQueryString=" + strQueryString);
        
        //get rid of the leading "?"
        strQueryString = strQueryString.substring(1);
            
        //split into name/value pairs
        aryPairs = strQueryString.split("&");
        
        strElement = strElement.toLowerCase();
        
        //search each querystring value and return the first match
        for (i=0; i < aryPairs.length; i++){
            
            WriteToDebug("Looking at element-" + aryPairs[i]);
            
            intEqualPos = aryPairs[i].indexOf('=');
            
            if (intEqualPos != -1){
            
                strArg = aryPairs[i].substring(0,intEqualPos);
                
                WriteToDebug("Arg=" + strArg);
                
                if (EqualsIgnoreCase(strArg, strElement)){
                
                    WriteToDebug("Found Match");
                    strValue = aryPairs[i].substring(intEqualPos+1);
                    
                    WriteToDebug("Decoding value-" + strValue);	
                    
                    strValue = new String(strValue)
                    
                    strValue = strValue.replace(/\+/g, "%20")
                    strValue = unescape(strValue);

                    WriteToDebug("Returning value-" + strValue);	
                    
                    return new String(strValue);
                }
            }
        } 
        
        //if we didn't find a match, return an empty string
        WriteToDebug("No Match Found, returning empty string");
        return "";
    }


    function EqualsIgnoreCase(str1, str2){
        
        WriteToDebug("In EqualsIgnoreCase str1=" + str1 + ", str2=" + str2);
        
        var blnReturn;
        
        str1 = new String(str1);
        str2 = new String(str2);
        
        blnReturn = (str1.toLowerCase() == str2.toLowerCase())
        
        WriteToDebug("Returning " + blnReturn);
        
        return blnReturn;
    }


    function ZeroPad(intNum, intNumDigits){

        var strTemp;
        var intLen;
        var i;
        
        strTemp = new String(intNum);
        intLen = strTemp.length;
        
        if (intLen > intNumDigits){
            strTemp = strTemp.substr(0,intNumDigits);
        }
        else{
            for (i=intLen; i<intNumDigits; i++){
                strTemp = "0" + strTemp;
            }
        }
        return strTemp;
    }
}

export default aicc_api;