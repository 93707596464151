import React , { Component } from 'react';

  export default class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
          modalShow: '',
          display: 'none'
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
      }

      openModal() {
        this.setState({
          modalShow: 'show',
          display: 'block'
        });
      }
    
      closeModal() {
        this.setState({
          modalShow: '',
          display: 'none'
        });
      }
    
      componentDidMount() {
        this.props.isOpen ? this.openModal() : this.closeModal();
      }
    
      componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
          this.props.isOpen ? this.openModal() : this.closeModal();
        }
      }

    render() {
        return (
            <div
                className={'modal fade ' + this.props.modalClass + ' ' + this.state.modalShow  }
                tabIndex="-1"
                role="dialog"
                aria-hidden="true"
                style={{ display: this.state.display }}>
                 <div className={'modal-dialog ' + this.props.dialogClass} role="document">
                    <div className="modal-content">
                        {this.props.children}
                    </div>
                 </div>
          </div>
        );
    }
};